import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import Vuelidate from 'vuelidate'
import router from './router'
import store from './store'
import './registerServiceWorker'
import VueMeta from 'vue-meta'

Vue.use(Vuelidate)
Vue.use(VueMeta)

Vue.config.productionTip = false

var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
