<template>
  <div class="home">
    <div v-if="!isMobDevice" class="bg_trgls">
      <img class="trgl-1" src="@/assets/img/trgl-1.svg" alt=" ">
      <svg class="path-1" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 354 257" style="enable-background:new 0 0 354 257;" xml:space="preserve" stroke="#000000" stroke-miterlimit="10">
        <path class="st0" d="M0,0c18.6,5.6,40,11,40,11c2.5,0.6,28.9,7.2,54.5,12c57.9,10.8,91.3,17,132,10c32-5.5,54.3-16.8,73.5-26.5
	c33.5-17,72-36.4,95.5-78.5c8.2-14.7,28.2-50.5,16.5-82c-5.5-14.9-23.2-42.4-126-61c-135.1-24.4-245.1-2.3-391,27
	c-22.7,4.6-40.5,10.4-76,22c-27.2,8.9-29,10.6-30,13C-225.9-119.1-61.2-18.5,0,0z" fill="none"/>
      </svg>

      <img class="trgl-2" src="@/assets/img/trgl-2.svg" alt=" ">
      <svg class="path-2" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 350 500" style="enable-background:new 0 0 350 500;" stroke="#000000" stroke-miterlimit="10">
        <path class="st0" d="M0,0c14.2-25.4,29.6-38.4,36.7-43.8c19.1-14.6,37.4-20,46.7-22.7c41.6-11.9,107.1-8.9,165.3,27.3
	C308.6-1.8,360.8,70.5,343.3,99.6c-12.3,20.5-61.7,23.5-93.3,16.7c-13.5-2.9-17.2-6.1-32.7-8c-8.2-1-35.1-3.4-66.7,8.7
	c-24.6,9.4-377.3,39.8-396,28C-288.8,117.6-30.8,55.2,0,0z" fill="none"/>
      </svg>

      <img class="trgl-3" src="@/assets/img/trgl-3.svg" alt=" ">
      <svg class="path-3" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 354 257" style="enable-background:new 0 0 354 257;" xml:space="preserve" stroke="#000000" stroke-miterlimit="10">
        <path class="st0" d="M0,0c-52.8,16.3-65.1,25.1-94.4,33.6c0,0-31.4,10.2-50.4,21.7c-1.9,1.1-1.6,1.3-8.1,3.8
	c-2.2,0.8-8.9,3.3-16.4,8.9c-0.7,0.5-4,3-6.3,5.3c-10.3,10.5-9.6,27.8-9.3,33.3c0.1,3.5,1,17,10,32c5,8.3,12.7,17.4,32,29.7
	c15.4,9.8,29.6,16.2,56.3,26c36,13.2,63.7,21.4,69.3,23c4,1.2,41.7,12.1,70.7,18.3c5,1.1,9.9,2.1,15.4,3
	c47.4,8.1,118.8,9.1,160.6,10c68.3,1.5,99.8,5.4,161.7-0.5c32.4-3.1,38.9-5.6,45-9.7c63.4-42.9,32.3-226.9-39.5-269
	C371.6-45.1,343-51,343-51c-24.1-5-44.6-3.9-56.9-3.2c-7.1,0.4-12.2,1-22.6,2.1c-9.7,1-22.8,2.8-50,7.2C129.6-31.4,69.7-21.5,0,0z" fill="none"/>
      </svg>

      <img class="trgl-4" src="@/assets/img/trgl-4.svg" alt=" ">
      <svg class="path-4" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 354 257" style="enable-background:new 0 0 354 257;" xml:space="preserve" stroke="#000000" stroke-miterlimit="10">
        <path class="st0" d="M0,0c-2.2-1.3-13-7.6-27.5-17C-43.5-27.4-45-29.1-56-36c-12.5-7.9-22.4-12.9-42-23c-9.6-4.9-21.7-10.9-36-17.5
	c0,0-12-5.5-30-12.5c-9.4-3.7-12.7-4.7-17-5.5c-9.1-1.7-16.4-1.1-24.5-0.5c-10.6,0.8-14.6,2.4-16,3c-5.5,2.3-9.2,5.4-11,7
	c-3,2.6-6.8,5.9-9.5,11.5c-2.4,5-2.7,9.4-3,13.5c-0.4,5.4,0.1,10.2,1,14.5c2.1,10.4,6.4,18.2,12.5,28.5c12.1,20.5,16,26,16,26
	c39.8,56.4,82,109,82,109c16.2,20.2,24.4,30.4,25,31c0,0,70,69.3,199,62.5c3.4-0.2,15.1-1.1,32.5-1.5c10.9-0.2,12,0,15-0.5
	c16.4-2.7,29.8-17.5,33.5-26C192.4,136,29.1,17.4,0,0z" fill="none"/>
      </svg>

    </div>

    <div v-if="isMobDevice">
      <HomeWelcom></HomeWelcom>
      <HomePortfolio></HomePortfolio>
      <HomeServices></HomeServices>
      <HomeAbout></HomeAbout>
      <HomeContact></HomeContact>
    </div>

    <div v-else>
      <transition @enter="enterFirstScreen" @leave="leaveFirstScreen">
        <HomeWelcom ref="welcom" v-if="activeScreen === 1"></HomeWelcom>
      </transition>

      <transition @enter="enterSecondScreen" @leave="leaveSecondScreen" appear>
        <HomePortfolio ref="portfolio" v-if="activeScreen === 2"></HomePortfolio>
      </transition>

      <transition @enter="enterThirdScreen" @leave="leaveThirdScreen" appear>
        <HomeServices ref="services" v-if="activeScreen === 3"></HomeServices>
      </transition>

      <transition @enter="enterFoursScreen" @leave="leaveFoursScreen" appear>
        <HomeAbout ref="about" v-if="activeScreen === 4"></HomeAbout>
      </transition>

      <transition @enter="enterFifthScreen" @leave="leaveFifthScreen" appear>
        <HomeContact ref="contact" v-if="activeScreen === 5"></HomeContact>
      </transition>
    </div>

  </div>

</template>

<script>
import { isMobile, isTablet } from "mobile-device-detect"
import HomeWelcom from '@/components/HomeWelcom'
import HomePortfolio from '@/components/HomePortfolio'
import HomeServices from '@/components/HomeServices'
import HomeAbout from '@/components/HomeAbout'
import HomeContact from '@/components/HomeContact'
import { debounce } from "@/mixins/debounce"
import anime from 'animejs/lib/anime.es.js'

let moveTrgl_1
let moveTrgl_2

export default {
  metaInfo: {
      title: process.env.VUE_APP_NAME,
      titleTemplate: '%s',
      htmlAttrs: {
          lang: 'en',
          amp: false
      },
      meta: [
        { name: 'title', content: process.env.VUE_APP_TITLE_HOME },
        { name: 'description', content: process.env.VUE_APP_DESCRIPTION_HOME },
        { name: 'keywords', content: process.env.VUE_APP_KEYWORDS_HOME },
        { property: 'og:title', content: process.env.VUE_APP_TITLE_HOME },
        { property: 'og:description', content: process.env.VUE_APP_DESCRIPTION_HOME }
      ],
      link: [
        { rel: 'canonical', href: process.env.VUE_APP_WEBSITE_URL }
      ]
  },
  name: 'Home',
  components: {
    HomeWelcom, HomePortfolio, HomeServices, HomeAbout, HomeContact
  },
  data: () => {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      isMounted: false,
      scroll: true,
      // scrollHash: []
    }
  },
  computed: {
    isMobDevice() {
      return isMobile || isTablet ? true : false
    },
    activeScreen() {
      return this.$store.state.activeScreen
    },
    scrollHash() {
      return this.$store.state.scrollHash
    },
    fadeValueComp() {
      return this.fadeValue
    }
  },
  methods: {

    scrollTop: function() {
      if (this.scroll) {
        this.scroll = false
        this.$store.commit('scrollHashPush', this.activeScreen)
        this.$store.commit('activeScreenDecr');
        setTimeout(() => { this.scroll = true }, 1700)
      }
    },

    scrollBottom: function() {
      if (this.scroll) {
        this.scroll = false
        this.$store.commit('scrollHashPush', this.activeScreen)
        this.$store.commit('activeScreenIncr');
        setTimeout(() => { this.scroll = true }, 1700)
      }
    },

    addOnWheel: function(e) {
      var delta = e.deltaY || e.detail || e.wheelDelta;
          // console.log('delta');
          // console.log(e);

      if (e.deltaY && e.target) {
        if (!(e.target.classList.contains("portfolio_img"))) {
          // e.preventDefault()

          if (delta > 0) {
            //scroll down
            // console.log('Down')
            if (this.isMobDevice && window.pageYOffset > 150) {
              this.$store.commit('activeScreenSetter', 2)
            } else if (!this.isMobDevice) {
              this.scrollBottom()
            }
          } else {
            //scroll up
            // console.log('Up')
            if (this.isMobDevice && window.pageYOffset < 400) {
              this.$store.commit('activeScreenSetter', 1)
            } else if (!this.isMobDevice) {
              this.scrollTop()
            }
          }

        } else {
          // e.preventDefault();
          // console.log('stop')
          // console.log(e)
        }
      } else {
        if (delta > 0) {
          //scroll down
          this.scrollBottom()
          // console.log('Down')
        } else {
          //scroll up
          this.scrollTop()
          if (this.isMobDevice && window.pageYOffset < 300) {
            this.$store.commit('activeScreenSetter', 1)
          }
          // console.log('Up')
        }
      }

      return false

    },

    enterFirstScreen: function (el, done) {
      // if (!this.isMobDevice) {
      //   this.$refs.welcom.$forceUpdate()
      // }
      if (this.isMounted) {
        const logo = document.querySelector('.home .logo')
        const title = document.querySelector('.main_title_wrap')
        const btnsWrap = document.querySelector('.buttons_wrap')
        // const trgl_1 = document.querySelector('.trgl-1')
        // const trgl_2 = document.querySelector('.trgl-2')
        // const trgl_3 = document.querySelector('.trgl-3')
        const trgl_4 = document.querySelector('.trgl-4')
        // moveTrgl_1.restart()
        anime({
          targets: [logo, title, btnsWrap],
          opacity: [0, 1],
          easing: 'linear',
          duration: 500,
          delay: anime.stagger(200, {start: 500}),
        })
        // anime({
        //   targets: '.trgl-1',
        //   translateX: ['-130px', 0],
        //   translateY: ['-40px', 0],
        //   scale: [1.1, 1],
        //   easing: 'easeOutQuad',
        //   duration: 1000,
        //   delay: 1000,
        //   complete: function() {
        //     let path = anime.path('.path-1 path');

        //     moveTrgl_1 = anime({
        //       targets: trgl_1,
        //       translateX: path('x'),
        //       translateY: path('y'),
        //       // rotate: path('angle'),
        //       rotateZ: '720deg',
        //       easing: 'linear',
        //       duration: 50000,
        //       delay: -100,
        //       loop: true,
        //     });
        //   }
        // })
        // anime({
        //   targets: '.trgl-2',
        //   translateX: ['-330px', 0],
        //   translateY: ['300px', 0],
        //   scale: [1.1, 1],
        //   easing: 'easeOutQuad',
        //   duration: 1500,
        //   delay: 500,
        //   complete: function() {
        //     let path = anime.path('.path-2 path');

        //     moveTrgl_2 = anime({
        //       targets: trgl_2,
        //       translateX: path('x'),
        //       translateY: path('y'),
        //       rotateZ: '-720deg',
        //       easing: 'linear',
        //       duration: 70000,
        //       delay: -100,
        //       loop: true,
        //     });
        //   }
        // })
        // anime({
        //   targets: '.trgl-3',
        //   translateX: ['400px', 0],
        //   translateY: ['-100px', 0],
        //   scale: [1.1, 1],
        //   easing: 'easeOutQuad',
        //   duration: 1500,
        //   delay: 500,
        //   complete: function() {
        //     let path = anime.path('.path-3 path');

        //     moveTrgl_2 = anime({
        //       targets: trgl_3,
        //       translateX: path('x'),
        //       translateY: path('y'),
        //       rotateZ: '-720deg',
        //       easing: 'linear',
        //       duration: 50000,
        //       delay: -50,
        //       loop: true,
        //     });
        //   }
        // })
        anime({
          targets: '.trgl-4',
          scale: 1,
          translateX: 0,
          translateY: 0,
          rotate: '0deg',
          easing: 'easeInQuart',
          duration: 1000,
          complete: function() {
            trgl_4.classList.remove('active')

            let path = anime.path('.path-4 path');

            moveTrgl_2 = anime({
              targets: trgl_4,
              translateX: path('x'),
              translateY: path('y'),
              // rotateZ: '-720deg',
              easing: 'linear',
              duration: 50000,
              delay: -50,
              loop: true,
            });
          }
        })
      }
    },
    leaveFirstScreen: function (el, done) {
      const logo = document.querySelector('.home .logo')
      const title = document.querySelector('.main_title_wrap')
      const btnsWrap = document.querySelector('.buttons_wrap')
      // const trgl_1 = document.querySelector('.trgl-1')
      // const trgl_2 = document.querySelector('.trgl-2')
      // const trgl_3 = document.querySelector('.trgl-3')
      // const trgl_4 = document.querySelector('.trgl-4')
      // const trgl_5 = document.querySelector('.trgl-5')
      // console.log(moveTrgl_1)
      // moveTrgl_1.pause()
      anime({
        targets: [logo, btnsWrap, title,],
        opacity: [1, 0],
        easing: 'linear',
        duration: 500,
        delay: anime.stagger(200),
        complete: function() {
          done()
        }
      })

      // anime({
      //   targets: '.trgl-1',
      //   translateX: [anime.get(trgl_1, 'translateX', 'px'), '-130px'],
      //   translateY: [anime.get(trgl_1, 'translateY', 'px'), '-40px'],
      //   rotateZ: '0deg',
      //   scale: [1, 1.1],
      //   easing: 'easeInQuart',
      //   duration: 800,
      //   complete: function() {
      //     anime.remove(trgl_1)
      //   }
      // })
      // anime({
      //   targets: '.trgl-2',
      //   translateX: [anime.get(trgl_2, 'translateX', 'px'), '-330px'],
      //   translateY: [anime.get(trgl_2, 'translateY', 'px'), '300px'],
      //   rotateZ: '0deg',
      //   scale: [1, 1.1],
      //   easing: 'easeInQuart',
      //   duration: 800,
      //   complete: function() {
      //     anime.remove(trgl_2)
      //   }
      // })
      // anime({
      //   targets: '.trgl-3',
      //   translateX: [anime.get(trgl_3, 'translateX', 'px'), '400px'],
      //   translateY: [anime.get(trgl_3, 'translateY', 'px'), '-100px'],
      //   rotateZ: '0deg',
      //   scale: [1, 1.1],
      //   easing: 'easeInQuart',
      //   duration: 800,
      //   complete: function() {
      //     anime.remove(trgl_3)
      //   }
      // })
    },

    enterSecondScreen: function (el, done) {
      // if (!this.isMobDevice) {
      //   this.$refs.portfolio.$forceUpdate()
      // }
      const title = document.querySelector('.s_second .s_title')
      // const text = document.querySelector('.s_second .text')
      const t1 = document.querySelector('.s_second .text-1')
      const t2 = document.querySelector('.s_second .text-2')
      // const t3 = document.querySelector('.s_second .text-1 + p + p')
      const btn = document.querySelector('.s_second .btn_more')
      const monitor = document.querySelector('.slider_portfolio')
      const trgl_4 = document.querySelector('.trgl-4')

      // let timeline = anime.timeline({
      //   easing: 'linear',
      //   duration: 1000
      // });

      anime({
        targets: '.trgl-4',
        rotate: ['-205deg'],
        translateX: [anime.get(trgl_4, 'translateX', 'px'), '-580px'],
        translateY: [anime.get(trgl_4, 'translateY', 'px'), '-420px'],
        // rotateZ: '0deg',
        scale: [1, 5],
        easing: 'linear',
        duration: 800,
        delay: 0,
        begin: function() {
          trgl_4.classList.add('active')
        },
        complete: function() {
          anime.remove(trgl_4)
        }
      })
      // anime({
      //   targets: '.s_second .block--left',
      //   translateX: ['-15vw', 0],
      //   opacity: [0, 1],
      //   easing: 'easeOutQuad',
      //   duration: 1000,
      //   delay: 1000,
      // })
      anime({
        targets: [title, t1, t2, btn],
        translateX: ['-10vw', 0],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 500,
        // delay: 700,
        delay: anime.stagger(100, {start: 500})
      })
      // anime({
      //   targets: btn,
      //   opacity: [0, 1],
      //   easing: 'easeOutQuad',
      //   duration: 500,
      //   delay: 1000,
      // })
      // anime({
      //   targets: '.s_second .block--left',
      //   translateX: ['-15vw', 0],
      //   opacity: [0, 1],
      //   easing: 'easeOutQuad',
      //   duration: 1000,
      //   delay: 1000,
      // })

      anime({
        targets: monitor,
        // translateX: ['10vw', 0],
        opacity: [0, 1],
        easing: 'easeInQuad',
        duration: 800,
        delay: 600,
        // complete: function() {
        //   done()
        // }
      });
    },
    leaveSecondScreen: function (el, done) {
      const title = document.querySelector('.s_second .s_title')
      const t1 = document.querySelector('.s_second .text-1')
      const t2 = document.querySelector('.s_second .text-2')
      const btn = document.querySelector('.s_second .btn_more')
      const monitor = document.querySelector('.slider_portfolio')
      const trgl_4 = document.querySelector('.trgl-4')

      // anime({
      //   targets: trgl_4,
      //   opacity: [1, 0],
      //   duration: 1500,
      // })
      anime({
        targets: [title, t1, t2, btn],
        translateX: [0, '-10vw'],
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 700,
        // delay: 700,
        delay: anime.stagger(100)
      })
      // anime({
      //   targets: '.s_second .block--left',
      //   translateX: [0, '-15vw'],
      //   opacity: [1, 0],
      //   easing: 'easeOutQuad',
      //   duration: 700,
      // })

      anime({
        targets: monitor,
        // translateX: ['10vw', 0],
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 700,
        complete: function() {
          done()
        }
      })
    },

    enterThirdScreen: function (el, done) {
      // if (!this.isMobDevice) {
      //   this.$refs.services.$forceUpdate()
      // }
      const title = document.querySelector('.s_third .s_title')
      const servicesBlock = document.querySelector('.s_third .block--left')
      const tablet = document.querySelector('.chosen_cat')
      const trgl_4 = document.querySelector('.trgl-4')
      const trgl_2 = document.querySelector('.trgl-2')

      anime({
        targets: '.trgl-4',
        translateX: [anime.get(trgl_4, 'translateX', 'px'), '400px'],
        translateY: [anime.get(trgl_4, 'translateY', 'px'), '-900px'],
        rotate: 0,
        scale: 1,
        easing: 'easeInQuart',
        duration: 700,
        begin: function() {
          trgl_4.classList.remove('active')
        },
        complete: function() {
          anime.remove(trgl_4)
        }
      })
      // anime({
      //   targets: '.trgl-2',
      //   translateX: ['-33rem', '47rem'],
      //   translateY: ['30rem', '-1rem'],
      //   scale: [1.1, 3],
      //   easing: 'easeOutQuad',
      //   duration: 1500,
      //   // delay: 500,
      //   begin: function() {
      //     trgl_2.classList.add('active')
      //   },
      // })
      anime({
        targets: title,
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 700,
        delay: 300,
      })
      anime({
        targets: tablet,
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 700,
        delay: 400,
        // complete: function() {
        //   done()
        // }
      })
      anime({
        targets: servicesBlock,
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 700,
        delay: 300,
        // complete: function() {
        //   done()
        // }
      })
    },
    leaveThirdScreen: function (el, done) {
      const title = document.querySelector('.s_third .s_title')
      const servicesBlock = document.querySelector('.categories')
      const tablet = document.querySelector('.chosen_cat')
      const trgl_2 = document.querySelector('.trgl-2')

      document.querySelectorAll('.cat_list_tab').forEach(function(el) { el.classList.remove('active') })

      // anime({
      //   targets: '.trgl-2',
      //   translateX: '-33rem',
      //   translateY: '30rem',
      //   scale: 1,
      //   easing: 'linear',
      //   duration: 1000,
      //   // delay: 500,
      //   begin: function() {
      //     trgl_2.classList.remove('active')
      //   },
      // })
      anime({
        targets: title,
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 700,
      })
      anime({
        targets: tablet,
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 700,
        // delay: 500,
      })
      anime({
        targets: servicesBlock,
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 700,
        // delay: 500,
      })

      anime({
        targets: '.bg_lines .st0',
        points: [
          { value: '666.3,245.1 439.7,365.9 252.1,215.5' },
        ],
        easing: 'linear',
        duration: 800,
      });
      anime({
        targets: '.cat_list_tab',
        translateX: 0,
        translateY: 0,
        easing: 'linear',
        duration: 800,
        complete: function() {
          done()
        }
      })
      anime({
        targets: '.trgl-4',
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 1000,
        complete: function() {
          done()
        }
      })
    },

    enterFoursScreen: function (el, done) {
      // if (!this.isMobDevice) {
      //   this.$refs.about.$forceUpdate()
      // }
      // const aboutContent = document.querySelector('.s_fours .s_content')
      const title = document.querySelector('.s_fours .s_title')
      const t1 = document.querySelectorAll('.s_fours .text-1 p')
      const t2 = document.querySelectorAll('.s_fours .text-2 p')
      const btn = document.querySelector('.s_fours .btn_more')
      const papaya = document.querySelector('.s_fours .bg_image')
      const trgl_4 = document.querySelector('.trgl-4')

      if (this.scrollHash[this.scrollHash.length - 1] != 5) {
        anime({
          targets: papaya,
          // translateY: ['-100vh', 0],
          // opacity: 1,
          // translateY: ['100vh', 0],
          opacity: [0, 1],
          easing: 'easeOutQuad',
          duration: 700,
          delay: 300,
        })
      }

      anime({
        targets: [title, t1, t2, btn],
        translateX: ['-10vw', 0],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 500,
        delay: anime.stagger(100, {start: 400}),
        // complete: function() {
        //   done()
        // }
      })
      anime({
        targets: '.trgl-4',
        translateX: [anime.get(trgl_4, 'translateX', 'px'), '200px'],
        translateY: [anime.get(trgl_4, 'translateY', 'px'), '200px'],
        scale: [1, 1.1],
        easing: 'easeInQuart',
        duration: 700,
        complete: function() {
          anime.remove(trgl_4)
        }
      })
    },
    leaveFoursScreen: function (el, done) {
      // const aboutContent = document.querySelector('.s_fours .s_content')
      const title = document.querySelector('.s_fours .s_title')
      const t1 = document.querySelectorAll('.s_fours .text-1 p')
      const t2 = document.querySelectorAll('.s_fours .text-2 p')
      const btn = document.querySelector('.s_fours .btn_more')
      const papaya = document.querySelector('.s_fours .bg_image')
      if (this.activeScreen != 5) {
        anime({
          targets: papaya,
            opacity: [1, 0],
            easing: 'easeOutQuad',
            duration: 700,
            delay: 200
          })
      }

      anime({
        targets: [title, t1, t2, btn],
        translateX: [0, '-10vw'],
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 700,
        delay: anime.stagger(100),
        complete: function() {
          done()
        }
      })
    },

    enterFifthScreen: function (el, done) {
      // if (!this.isMobDevice) {
      //   this.$refs.contact.$forceUpdate()
      // }
      const contact_items = document.querySelectorAll('.our_contacts .contacts_item')
      const request_items = document.querySelectorAll('.make_request')
      const title = document.querySelector('.s_contact_us .s_title')
      const btn = document.querySelector('.s_contact_us .btn_request')
      const smoke = document.querySelector('.s_contact_us .bg_image')
      // const form = document.querySelector('.s_contact_us .s_content')
      const trgl_4 = document.querySelector('.trgl-4')
      const papaya = document.querySelector('.s_fours .bg_image')

      // anime({
      //   targets: form,
      //   translateX: ['-15vw', 0],
      //   opacity: [0, 1],
      //   easing: 'easeOutQuad',
      //   duration: 700,
      //   delay: 500,
      //   complete: function() {
      //     done()
      //   }
      // })

      // setTimeout(function() {
      //   const title = document.querySelector('.s_contact_us .s_title')
      //   const contact_items = document.querySelectorAll('.our_contacts .contacts_item')
      //   const btn = document.querySelector('.s_contact_us .btn_more')
      //   console.log(title)
      //   console.log(contact_items)
      //   console.log(btn)
      //   anime({
      //     targets: [title, contact_items, btn],
      //     translateX: ['-10vw', 0],
      //     opacity: [0, 1],
      //     easing: 'easeOutQuad',
      //     duration: 700,
      //     delay: anime.stagger(100, {start: 300}),
      //   })
      // }, 200)
      anime({
        targets: [title, contact_items, btn],
        translateX: ['-10vw', 0],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 700,
        delay: anime.stagger(100, {start: 300}),
      })
      anime({
        targets: [request_items],
        // translateX: ['-10vw', 0],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 500,
        delay: 1200,
      })

      if (this.scrollHash[this.scrollHash.length - 1] == 4) {
        anime({
          targets: smoke,
          translateY: ['100vh', 0],
          // opacity: [0, 1],
          easing: 'easeOutQuad',
          duration: 700,
          // delay: 1000,
        })
        anime({
          targets: papaya,
          translateY: [0, '-100vh'],
          // opacity: [0, 1],
          easing: 'easeOutQuad',
          duration: 700,
          // delay: 1000,
        })
      } else {
        anime({
          targets: smoke,
          // translateY: ['-100vh', 0],
          // opacity: 1,
          // translateY: ['100vh', 0],
          opacity: [0, 1],
          easing: 'easeOutQuad',
          duration: 700,
          delay: 200,
        })
        anime({
          targets: papaya,
          opacity: [1, 0],
          easing: 'easeOutQuad',
          duration: 700,
          // delay: 1000,
        })
      }

      anime({
        targets: '.trgl-4',
        translateX: [anime.get(trgl_4, 'translateX', 'px'), '200px'],
        translateY: [anime.get(trgl_4, 'translateY', 'px'), '200px'],
        scale: [1, 1.1],
        easing: 'easeInQuart',
        duration: 700,
        complete: function() {
          anime.remove(trgl_4)
        }
      })
    },
    leaveFifthScreen: function (el, done) {
      const contact_items = document.querySelectorAll('.our_contacts .contacts_item')
      const title = document.querySelector('.s_contact_us .s_title')
      const btn = document.querySelector('.s_contact_us .btn_request')
      const smoke = document.querySelector('.s_contact_us .bg_image')
      // const form = document.querySelector('.s_contact_us .s_content')
      const papaya = document.querySelector('.s_fours .bg_image')

      if(this.$store.state.showRequestForm) {
        anime({
          targets: document.querySelector('.make_request'),
          translateX: [0, '-10vw'],
          // opacity: [1, 0],
          easing: 'easeOutQuad',
          duration: 700,
          complete: function() {
            done()
          }
        })
        this.$store.commit('showRequestFormToggler')
      }

      if (this.activeScreen === 4) {
        anime({
          targets: smoke,
          translateY: [0, '100vh'],
          // opacity: [0, 1],
          easing: 'easeOutQuad',
          duration: 700,
          // delay: 1000,
        })
        anime({
          targets: papaya,
          translateY: ['-100vh', 0],
          // opacity: 1,
          easing: 'easeOutQuad',
          duration: 700,
          // delay: 1000,
        })
      } else {
        anime({
          targets: smoke,
          // translateY: [0, '100vh'],
          opacity: [1, 0],
          easing: 'easeOutQuad',
          duration: 700,
          // delay: 1000,
        })
      }
      // anime({
      //   targets: form,
      //   translateX: [0, '-15vw'],
      //   opacity: [1, 0],
      //   easing: 'easeOutQuad',
      //   duration: 700,
      //   complete: function() {
      //     done()
      //   }
      // })
      anime({
        targets: [title, contact_items, btn],
        translateX: [0, '-10vw'],
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 700,
        delay: anime.stagger(100),
        complete: function() {
          done()
        }
      })
    },

  },
  beforeMount() {
    this.$store.commit('activeScreenSetter', 1)
  },
  mounted() {
      this.isMounted = true
      const logo = document.querySelector('.home .logo')
      const title = document.querySelector('.main_title_wrap')
      const btnsWrap = document.querySelector('.buttons_wrap')
      const panelTop = document.querySelector('.panel--top')
      const panelBottom = document.querySelector('.panel--bottom')
      const panelLeft = document.querySelector('.panel--left')
      const panelRight = document.querySelector('.panel--right')
      const trgl_1 = document.querySelector('.trgl-1')
      const trgl_2 = document.querySelector('.trgl-2')
      const trgl_3 = document.querySelector('.trgl-3')
      const trgl_4 = document.querySelector('.trgl-4')

      if (!this.isMobDevice) {

        anime({
          targets: '.trgl-1',
          translateX: ['-130px', 0],
          translateY: ['-40px', 0],
          scale: [1.1, 1],
          easing: 'easeOutQuad',
          duration: 1000,
          delay: 1000,
          complete: function() {
            let path = anime.path('.path-1 path');

            moveTrgl_1 = anime({
              targets: trgl_1,
              translateX: path('x'),
              translateY: path('y'),
              // rotate: path('angle'),
              rotateZ: '720deg',
              easing: 'linear',
              duration: 50000,
              delay: -100,
              loop: true,
            });
          }
        })
        anime({
          targets: '.trgl-2',
          translateX: ['-330px', 0],
          translateY: ['300px', 0],
          scale: [1.1, 1],
          easing: 'easeOutQuad',
          duration: 1500,
          delay: 500,
          complete: function() {
            let path = anime.path('.path-2 path');

            moveTrgl_2 = anime({
              targets: trgl_2,
              translateX: path('x'),
              translateY: path('y'),
              rotateZ: '-720deg',
              easing: 'linear',
              duration: 70000,
              delay: -100,
              loop: true,
            });
          }
        })
        anime({
          targets: '.trgl-3',
          translateX: ['400px', 0],
          translateY: ['-100px', 0],
          scale: [1.1, 1],
          easing: 'easeOutQuad',
          duration: 1500,
          delay: 500,
          complete: function() {
            let path = anime.path('.path-3 path');

            moveTrgl_2 = anime({
              targets: trgl_3,
              translateX: path('x'),
              translateY: path('y'),
              rotateZ: '-720deg',
              easing: 'linear',
              duration: 50000,
              delay: -50,
              loop: true,
            });
          }
        })
        anime({
          targets: '.trgl-4',
          translateX: ['200px', 0],
          translateY: ['400px', 0],
          scale: [1.1, 1],
          easing: 'easeOutQuad',
          duration: 1500,
          delay: 500,
          complete: function() {
            let path = anime.path('.path-4 path');

            moveTrgl_2 = anime({
              targets: trgl_4,
              translateX: path('x'),
              translateY: path('y'),
              // rotateZ: '-720deg',
              easing: 'linear',
              duration: 50000,
              delay: -50,
              loop: true,
            });
          }
        })
        anime({
          targets: '.bg_trgls',
          opacity: [0, 1],
          easing: 'linear',
          duration: 1500,
          delay: 0,
        })
      } else {
        anime({
          targets: '.s_first',
          opacity: [0, 1],
          easing: 'linear',
          duration: 500,
          delay: 100,
        })

        document.addEventListener('touchend', (e) => {
          // console.log(e)
          // console.log(window.scrollY)
          if (window.scrollY > 300) {
            this.$store.commit('activeScreenSetter', 2)
          } else {
            this.$store.commit('activeScreenSetter', 1)
          }
        })
      }

      // anime({
      //   targets: panelTop,
      //   translateY: ['-150px', 0],
      //   opacity: [0, 1],
      //   easing: 'linear',
      //   duration: 1000,
      //   delay: 0,
      // })
      anime({
        targets: panelBottom,
        translateY: ['150px', 0],
        opacity: [0, 1],
        easing: 'linear',
        duration: 1000,
        delay: 0,
      })
      anime({
        targets: panelLeft,
        translateX: ['-100px', 0],
        opacity: [0, 1],
        easing: 'linear',
        duration: 1000,
        delay: 500,
      })
      anime({
        targets: panelRight,
        translateX: ['100px', 0],
        opacity: [0, 1],
        easing: 'linear',
        duration: 1000,
        delay: 500,
      })
      anime({
        targets: [logo, title, btnsWrap],
        opacity: [0, 1],
        easing: 'linear',
        duration: 1000,
        delay: anime.stagger(300, {start: 500}),
      })
      anime({
        targets: document.querySelector('.home'),
        opacity: [0, 1],
        easing: 'linear',
        duration: 200,
      });
    // let logo = document.querySelector('.logo_img_clone')
    // let logoMoved = document.querySelector('.logo_img_clone')
    const screenChangeHandler = function(e) {
      // let slider = document.querySelector('.VueCarousel')
      // console.log(slider)
      // console.log(e.path)
      // if (e.target == slider || slider.contains(target)){
      //     console.log('scroll on slider')
      // }
      // console.log(e.deltaY)
      if (e.deltaY == 100) {
          //scroll down
          this.scrollBottom()
          // console.log('Down')
      } else {
          //scroll up
          this.scrollTop()
          // console.log('Up')
      }
    }

    const page = document.querySelector('.home')

    // const addOnWheel = function(e) {
    //   var delta = e.deltaY || e.detail || e.wheelDelta;
    //       // console.log('delta');
    //       // console.log(e);

    //   if (e.deltaY && e.target) {
    //     if (!(e.target.classList.contains("portfolio_img"))) {
    //       // e.preventDefault()

    //       if (delta > 0) {
    //         //scroll down
    //         // console.log('Down')
    //         if (this.isMobDevice && window.pageYOffset > 150) {
    //           this.$store.commit('activeScreenSetter', 2)
    //         } else if (!this.isMobDevice) {
    //           this.scrollBottom()
    //         }
    //       } else {
    //         //scroll up
    //         // console.log('Up')
    //         if (this.isMobDevice && window.pageYOffset < 400) {
    //           this.$store.commit('activeScreenSetter', 1)
    //         } else if (!this.isMobDevice) {
    //           this.scrollTop()
    //         }
    //       }

    //     } else {
    //       // e.preventDefault();
    //       // console.log('stop')
    //       // console.log(e)
    //     }
    //   } else {
    //     if (delta > 0) {
    //       //scroll down
    //       this.scrollBottom()
    //       // console.log('Down')
    //     } else {
    //       //scroll up
    //       this.scrollTop()
    //       if (this.isMobDevice && window.pageYOffset < 300) {
    //         this.$store.commit('activeScreenSetter', 1)
    //       }
    //       // console.log('Up')
    //     }
    //   }

    //   return false

    // };

    // if (document.addEventListener) {
    //   if ('onwheel' in document) {
    //     // page.addEventListener("wheel", addOnWheel.bind(this));
    //     // window.onwheel = addOnWheel.bind(this)
    //     document.addEventListener('wheel', addOnWheel.bind(this), { passive: false })
    //   } else if ('onmousewheel' in document) {
    //     document.addEventListener("mousewheel", addOnWheel.bind(this))
    //   } else {
    //     document.addEventListener("MozMousePixelScroll", addOnWheel.bind(this))
    //   }
    // } else {
    //     document.attachEvent("onmousewheel", addOnWheel.bind(this))
    // }

    document.addEventListener('wheel', this.addOnWheel, { passive: false })

    // function addOnWheel(elem, handler) {
    //   if (elem.addEventListener) {
    //     if ('onwheel' in document) {
    //       elem.addEventListener("wheel", handler);
    //     } else if ('onmousewheel' in document) {
    //       elem.addEventListener("mousewheel", handler);
    //     } else {
    //       elem.addEventListener("MozMousePixelScroll", handler);
    //     }
    //   } else {
    //       text.attachEvent("onmousewheel", handler);
    //   }
    // }


    // document.querySelector('.home').addEventListener('wheel', screenChangeHandler.bind(this))

    // document.onwheel = function(e) {
    //   console.log(e.deltaY)
    //   if (e.deltaY > 0) {
    //       //scroll down
    //       this.scrollBottom();
    //       console.log('Down');
    //   } else {
    //       //scroll up
    //       this.scrollTop();
    //       console.log('Up');
    //   }
    // }.bind(this);
  },
  beforeRouteLeave (to, from, next) {
    if (this.isMobDevice) {
      anime({
        targets: document.querySelector('.home'),
        opacity: 0,
        easing: 'linear',
        duration: 500,
        complete: function() {
          next();
        }
      })
    } else {
      document.removeEventListener('wheel', this.addOnWheel)
      anime({
        targets: document.querySelector('.home'),
        opacity: 0,
        easing: 'linear',
        duration: 800,
        complete: function() {
          next();
        }
      })
    }

    // anime({
    //   targets: document.querySelector('.panel--left'),
    //   translateX: [0, -100],
    //   opacity: [1, 0],
    //   easing: 'linear',
    //   duration: 1000,
    //   delay: 300,
    // });
    // next();
    // setTimeout(() => {

    // }, 50)
    // anime({
    //   targets: document.querySelector('.page_change_preloader svg'),
    //   opacity: [0, 1],
    //   easing: 'linear',
    //   duration: 100,
    // });

  },

}
</script>

<style lang="scss">
.home {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  .panel--fixed {
    opacity: 0;
  }
  .bg_trgls {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    svg {
      position: absolute;
    }
    img {
      position: absolute;
    }
    .bg_trgl {
      position: absolute;
      svg {
        position: relative;
      }
    }
  }
  .trgl-wrap-1 {
    position: relative;
    left: 1.6rem;
    top: 15.5rem;
    width: 500px;
    background-color: rgba(#333, .2);
  }
  .trgl-1 {
    left: 1.6rem;
    top: 17.5rem;
  }
  .path-1 {
    opacity: 0;
    left: 0;
    top: 0;
    width: 36rem;
    position: absolute;
  }
  .path-2 {
    opacity: 0;
    left: 0;
    bottom: 0;
    width: 60rem;
    position: absolute;
  }
  .path-3 {
    opacity: 0;
    right: 0;
    top: 0;
    width: 36rem;
    position: absolute;
  }
  .path-4 {
    opacity: 0;
    left: 0;
    bottom: 0;
    width: 36rem;
    position: absolute;
  }
  .trgl-2 {
    left: 26.5rem;
    bottom: 20.4rem;
  }
  .trgl-3 {
    right: 32.5rem;
    top: 2.5rem;
  }
  .trgl-4 {
    width: 14.2rem;
    right: -30px;
    bottom: 68vh;
    margin-bottom: -580px;
    // transform: translateX(30rem) translateY(30rem);
    filter: blur(10px);
    will-change: transform;
    transition: filter 1.5s;
    &.active {
      filter: blur(2px);
      transition: filter 1.5s;
    }
  }
  // .trgl-5 {
  //   right: -73rem;
  //   bottom: -32rem;
  //   transform: translate3d(-89rem, -28rem, 0);
  // }
}
@media (min-width: 1900px) and (min-height: 1100px) {
  .home .trgl-4 {
    // bottom: 160px;
  }
}
@media (max-width: 1900px) {
  .home .trgl-4 {
    right: -110px;
    bottom: 70vh;
  }
}
@media (max-width: 1599px) {
  .home .trgl-4 {
    right: -160px;
    // bottom: -30px;
  }
}
@media (max-width: 1439px) {
  .home .trgl-4 {
    right: -160px;
    bottom: 75vh;
  }
}
@media (max-width: 1365px) {
  .home .trgl-4 {
    right: -260px;
    bottom: 75vh;
  }
}

@media (min-width: 992px) and (max-width: 1366px) {
  .mobile, .tablet {
    .home {
      position: relative;
      height: auto;
    }
    .home .section {
      padding-top: 50px;
      position: relative;
      left: auto;
      right: auto;
    }
  }
}
@media (max-width: 991px) {
  .home {
    position: relative;
    height: auto;
  }
  .home .section {
    position: relative;
    left: auto;
    right: auto;
  }
}
@media (max-width: 767px) {
}
</style>
